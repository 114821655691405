<template>
	<b-modal
		id="update-credentials"
		title="Update Credentials"
		ref="modal"
		cancel-title="Logout"
		ok-title="Submit"
		ok-variant="primary"
		@ok.prevent="handleOk"
		@show="onReset"
		@cancel.prevent="logout"
		:ok-disabled="isLoading"
		no-close-on-backdrop
		no-close-on-esc
		hide-header-close
	>
		<b-container fluid>
			<loading
				:active.sync="isLoading"
				loader="spinner"
				color="#20A8D8"
				:is-full-page="false"
			/>

			<b-form novalidate>
				<b-alert show variant="warning">
					<i class="fa fa-exclamation-circle"></i>&nbsp; You need to relogin
					after you update your credentials
				</b-alert>

				<b-form-group label="Contact No:" label-for="contactNo" description>
					<b-form-input
						name="Contact No"
						type="text"
						v-model="form.contactNo"
						v-validate="{
							required: true,
							regex: '^[+]639[0-9]{9}$',
						}"
						placeholder="+639xxxxxxxxx"
					/>
					<span v-show="errors.has('Contact No')" class="help-block">
						{{ 'Contact No must follow this format: +639xxxxxxxxx' }}
					</span>
				</b-form-group>

				<b-form-group label="Password:" label-for="password">
					<b-form-input
						name="Password"
						type="password"
						v-model="form.password"
						v-validate="{
							required: true,
							regex: passwordRegex,
						}"
						placeholder="Password"
						autocomplete="new-password"
					/>
					<span v-show="errors.has('Password')" class="help-block">{{
						passwordErrorMsg
					}}</span>
				</b-form-group>

				<b-form-group
					label="Confirm Password:"
					label-for="confirmPassword"
					description
				>
					<b-form-input
						name="Confirm Password"
						type="password"
						v-model="form.confirmPassword"
						v-validate="{
							required: true,
							regex: passwordRegex,
						}"
						placeholder="Confirm Password"
						autocomplete="new-password"
					/>
					<span v-show="errors.has('Confirm Password')" class="help-block">{{
						errors.first('Confirm Password')
					}}</span>
				</b-form-group>
			</b-form>
		</b-container>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// API
import userApi from '@/api/userApi';

// Others
import { firebase, db } from '@/config/firebase';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'update-credentials',
	components: { Loading },
	data() {
		return {
			form: {
				contactNo: '',
				password: '',
				confirmPassword: '',
			},

			loggedUser: this.$store.getters.loggedUser,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		passwordRegex() {
			return config.passwordRegex;
		},
		passwordErrorMsg() {
			return config.passwordErrorMsg;
		},
	},
	methods: {
		async logout() {
			await this.$store.dispatch('logout');
			let previousState = this.$store.getters.previousState;
			if (!_.isEmpty(previousState)) {
				this.$store.replaceState(previousState);
			}

			this.$router.push('/login');
		},
		async handleOk() {
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				this.isLoading = false;
				return;
			}

			this.handleSubmit();
		},
		getUserObject(param) {
			return {
				id: param.id,
				firstName: param.firstName,
				middleName: param.middleName,
				lastName: param.lastName,
				company: param.company,
				companyId: param.companyId,
				position: param.position,
				type: param.type,
				emailAddress: param.emailAddress,
				contactNo: param.contactNo,
				isActive: param.isActive,
				isNewUser: param.isNewUser,
				isPasswordExpired: param.isPasswordExpired,
				passwordExpirationDate: param.passwordExpirationDate,
				hasNotif: param.hasNotif,
				has2WayAuth: param.has2WayAuth,
				password: param.password,
				dateCreated: parseInt(param.dateCreated),
				createdBy: param.createdBy,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				updatedBy: this.loggedUser.id,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			// mark user to be an existing user
			this.form.isNewUser = false;

			try {
				let userObj = this.getUserObject(this.form);
				let { data } = await userApi.updateUser(
					userObj,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				// hide loading indicator
				this.isLoading = false;

				if (data.isSuccess) {
					// Prompt relogin with new password
					this.$toaster.success(
						'Your credentials was updated successfully. Please re-login your account.'
					);
					this.logout();
				} else {
					let error = data.errors[0];
					if (
						(!_.isEmpty(error.code) &&
							error.code === 'auth/email-already-exists') ||
						error.code === 'auth/phone-number-already-exists'
					) {
						this.$toaster.error(error.message);
					} else {
						this.$toaster.error(error);
					}
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;

				this.$toaster.error(
					`Error updating your credentials. Please try again.`
				);
			}
		},
		onReset() {
			this.form = Object.assign({}, this.loggedUser);
		},
	},
};
</script>
