<template>
	<b-modal
		v-model="dialogState"
		id="notification-dispatch-details-modal"
		ref="notification-dispatch-details-modal"
		title="Dispatch Details"
		ok-only
	>
		<div
			v-if="Object.keys(getDispatchDetails).length === 0"
			:style="{ height: '100px' }"
		>
			<loading
				:active.sync="isLoading"
				loader="spinner"
				color="#20A8D8"
				:is-full-page="false"
			/>
		</div>
		<div v-else>
			<!-- Primary Information -->
			<b-row>
				<b-col md="12" class="md-left-text-sm-right">
					<b>PRIMARY INFORMATION</b>
				</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Source:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left"
					>{{ getDispatchDetails.source.company }} -
					{{ getDispatchDetails.source.storageLocation }}</b-col
				>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Destination:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left"
					>{{ getDispatchDetails.destination.company }} -
					{{ getDispatchDetails.destination.storageLocation }}</b-col
				>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Notes:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					getDispatchDetails.notes
				}}</b-col>
			</b-row>

			<!-- Transportation -->
			<b-row class="mt-4">
				<b-col md="12" class="md-left-text-sm-right">
					<b>TRANSPORTATION</b>
				</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Plate No/CR/OR:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					getDispatchDetails.transportation.plateNo
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Company:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					getDispatchDetails.transportation.company
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Driver:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					getDispatchDetails.driver.name
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Assistants:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					!getDispatchDetails.transportation.assistants
						? '--'
						: getDispatchDetails.transportation.assistants
				}}</b-col>
			</b-row>
			<!-- Damaged Assets -->
			<b-row
				class="mt-4"
				v-if="
					getDispatchDetails.damagedAssets &&
					getDispatchDetails.damagedAssets.length > 0
				"
			>
				<b-col md="12" class="md-left-text-sm-right">
					<b>DAMAGED ASSETS</b>
				</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col md="12" class="md-left-text-sm-right">
					<span
						v-bind:key="asset"
						v-for="asset in getDispatchDetails.damagedAssets"
					>
						<b-badge pill variant="danger">{{ asset }}</b-badge
						>&nbsp;
					</span>
				</b-col>
			</b-row>
		</div>
	</b-modal>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapGetters } from 'vuex';

export default {
	name: 'notification-dispatch-details',
	components: {
		Loading,
	},
	data() {
		return {
			isLoading: true,
		};
	},
	computed: {
		...mapGetters('notificationDispatchDetails', [
			'getDialogState',
			'getDispatchDetails',
		]),
		dialogState: {
			get() {
				return this.getDialogState;
			},
			set() {
				this.$store.commit('notificationDispatchDetails/TOGGLE_DIALOG');
			},
		},
	},
};
</script>