<template>
	<b-modal
		id="user-profile"
		size="lg"
		title="User Profile"
		ref="modal"
		ok-title="Save"
		@ok="handleOk"
		@show="onReset"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="my-2">
					<b-col sm="4">
						<b-form-group label="First Name:" label-for="firstName" description>
							<b-form-input
								name="First Name"
								type="text"
								v-model="form.firstName"
								v-validate="{ required: true, regex: /^([A-Za-z\s]{1,25})$/ }"
								placeholder="First Name"
							/>
							<span v-show="errors.has('First Name')" class="help-block">{{
								errors.first('First Name')
							}}</span>
						</b-form-group>
					</b-col>
					<b-col sm="4">
						<b-form-group
							label="Middle Name:"
							label-for="middleName"
							description
						>
							<b-form-input
								name="Middle Name"
								type="text"
								v-model="form.middleName"
								v-validate="{ required: false, regex: /^([A-Za-z\s]{0,25})$/ }"
								placeholder="Middle Name"
							/>
							<span v-show="errors.has('Middle Name')" class="help-block">{{
								errors.first('Middle Name')
							}}</span>
						</b-form-group>
					</b-col>
					<b-col sm="4">
						<b-form-group label="Last Name:" label-for="lastName" description>
							<b-form-input
								name="Last Name"
								type="text"
								v-model="form.lastName"
								v-validate="{ required: true, regex: /^([A-Za-z\s]{1,25})$/ }"
								placeholder="Last Name"
							/>
							<span v-show="errors.has('Last Name')" class="help-block">{{
								errors.first('Last Name')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row class="my-2">
					<b-col sm="4">
						<b-form-group
							label="Job Position:"
							label-for="position"
							description
						>
							<b-form-input
								name="Position"
								type="text"
								v-model="form.position"
								v-validate="'required'"
								placeholder
							/>
							<span v-show="errors.has('Position')" class="help-block">{{
								errors.first('Position')
							}}</span>
						</b-form-group>
					</b-col>
					<b-col sm="4">
						<b-form-group label="Access Type:" label-for="type" description>
							<b-form-input
								name="Type"
								type="text"
								v-model="form.type"
								v-validate="'required'"
								placeholder
								disabled
							/>
							<span v-show="errors.has('Type')" class="help-block">{{
								errors.first('Type')
							}}</span>
						</b-form-group>
					</b-col>
					<b-col sm="4">
						<b-form-group label="Contact No:" label-for="contactNo" description>
							<b-form-input
								name="Contact No"
								type="text"
								v-model="form.contactNo"
								v-validate="{
									required: true,
									regex: '^[+]639[0-9]{9}$',
								}"
								placeholder="+639xxxxxxxxx"
							/>
							<span v-show="errors.has('Contact No')" class="help-block">
								{{ 'Contact No must follow this format: +639xxxxxxxxx' }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { UserUtil } from '@/utils/userutil';
import { ValidationUtil } from '@/utils/validationUtil';

// DAO and API
import userDAO from '@/database/users';
import userApi from '@/api/userApi';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'user-profile',
	components: {
		Loading,
	},
	data() {
		return {
			form: {
				id: '',
				firstName: '',
				middleName: '',
				lastName: '',
				company: '',
				companyId: '',
				position: '',
				type: null,
				contactNo: '',
				emailAddress: '',
				isActive: 'true',
				hasNotif: false,
				has2WayAuth: false,
				isNewUser: true,
				companyAccess: [],
				dateCreated: '',
				createdBy: '',
				dateUpdated: '',
				updatedBy: '',
			},

			allCompaniesObj: {},
			loggedUser: this.$store.getters.loggedUser,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.form.firstName + ' ' + this.form.lastName;
		},
	},
	mounted() {
		try {
			setTimeout(() => {
				// Don't initiate data retrieval when the account is not authenticated
				if (!this.$store.getters.isAuthenticated) {
					return;
				}

				// show loading indicator
				this.isLoading = true;

				this.allCompaniesObj = this.$store.getters.companies;

				// hide loading indicator
				this.isLoading = false;
			}, config.timeout);
		} catch (error) {
			this.$toaster.error('Error loading data. Please reload the page again.');
		}
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return;
			}

			this.handleSubmit();
		},

		getUserObject(param) {
			return {
				id: param.id,
				firstName: param.firstName,
				middleName: param.middleName,
				lastName: param.lastName,
				company: param.company,
				companyId: param.companyId,
				position: param.position,
				type: param.type,
				emailAddress: param.emailAddress,
				contactNo: param.contactNo,
				isActive: param.isActive,
				isNewUser: param.isNewUser,
				isPasswordExpired: param.isPasswordExpired,
				passwordExpirationDate: param.passwordExpirationDate,
				hasNotif: param.hasNotif,
				has2WayAuth: param.has2WayAuth,
				companyAccess: param.companyAccess,
				dateCreated: parseInt(param.dateCreated),
				createdBy: param.createdBy,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				updatedBy: this.loggedUser.id,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			this.processFormValues();

			try {
				let userObj = this.getUserObject(this.form);
				let { data } = await userApi.updateUser(
					userObj,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				// hide loading indicator
				this.isLoading = false;

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseEditUser', data.user);
					this.$refs.modal.hide();
				} else {
					let error = data.errors[0];
					if (
						(!_.isEmpty(error.code) &&
							error.code === 'auth/email-already-exists') ||
						error.code === 'auth/phone-number-already-exists'
					) {
						this.$toaster.error(error.message);
					} else {
						this.$toaster.error(error);
					}
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;

				this.$toaster.error(
					`Error updating user "${this.name}". Please try again.`
				);
			}
		},
		processFormValues() {
			// Removes excess whitespace
			this.form.firstName = ValidationUtil.removeExcessWhiteSpace(
				this.form.firstName
			);
			this.form.lastName = ValidationUtil.removeExcessWhiteSpace(
				this.form.lastName
			);
			this.form.middleName = ValidationUtil.removeExcessWhiteSpace(
				this.form.middleName
			);
			this.form.position = ValidationUtil.removeExcessWhiteSpace(
				this.form.position
			);
		},

		async onReset() {
			// show loading indicator
			this.isLoading = true;

			let userId = this.$store.getters.email;
			this.form = await userDAO.getUserById(userId);

			// init company access
			if (_.isEmpty(this.form.companyAccess)) {
				this.form.companyAccess = await UserUtil.getDefaultCompanyAccess(
					this.form,
					this.allCompaniesObj
				);
			}

			// hide loading indicator
			this.isLoading = false;

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>

<style>
</style>

