<template>
	<b-modal
		id="force-logout-confirmation"
		title="Your login session has expired"
		@ok="onForceLogoutConfirmation"
		:no-close-on-backdrop="true"
		:no-close-on-esc="true"
		:hide-header-close="true"
		centered
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>
		<p>
			This session has expired because another session was started on another
			browser or device.
		</p>

		<template v-slot:modal-footer class="text-left">
			<b-button
				type="submit"
				variant="danger"
				@click="onForceLogoutConfirmation"
				:disabled="isLoading"
			>
				<i class="fa fa-arrow-left"></i>&nbsp; BACK TO LOGIN
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import { firebase } from '@/config/firebase';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'ForceLogoutConfirmation',
	components: { Loading },
	props: {
		email: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			isLoading: false,
		};
	},
	methods: {
		async onForceLogoutConfirmation(evt) {
			evt.preventDefault();
			this.isLoading = true;

			try {
				firebase.auth().signOut();
				this.$bvModal.hide('force-logout-confirmation');
				this.$router.replace('/login');
			} catch (error) {
				this.$toaster.warning('Oops! Something went wrong, please try again.');
			}
			// hide loading indicator
			this.isLoading = false;
		},
	},
};
</script>